<template>
  <div class="emails-to-notify">
    <label class="emails-to-notify__label">
      Notify email (Include email addresses to receive the shipping documents):
    </label>
    <Chips
      :class="errorMissingEmail ? 'missing-field' : ''"
      v-model.trim="emails"
      class="input-information-email"
      :placeholder="!emails.length ? 'Press enter to add email' : ''"
      separator
      @add="handleAddEmail"
      @remove="handleAddEmail"
    />
    <span v-if="errorMissingEmail" class="error-message">At least one email is required* </span>
  </div>
</template>

<script>
import Chips from 'primevue/chips';

export default {
  name: 'EmailsGroupInputs',
  components: {
    Chips,
  },
  props: {
    errorMissingEmail: Boolean,
  },
  data() {
    return {
      emails: [],
    };
  },
  methods: {
    handleAddEmail(params) {
      const emailsArray = params.value;
      const lastEmailAdded = emailsArray[emailsArray.length - 1];
      const isValidEmail = this.validateEmailFormat(lastEmailAdded);
      if (!isValidEmail) {
        this.emails.pop();
      }
      const payload = {
        propName: 'additional_emails',
        info: this.emails,
      };
      this.$store.commit('booking/setSendBooking', payload);
    },
    validateEmailFormat(email) {
      const regexp = new RegExp(
        /^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/,
      );
      return regexp.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.emails-to-notify {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 20px 0px;
  text-align: left;
  &__label {
    margin: 0px;
    font-weight: bold;
  }
  &__input {
    width: 100%;
    &:first-child {
      margin-right: 5px;
    }
  }
}

.input-information-email {
  display: block;
  width: 100%;
}

.error-message {
  color: red;
  font-size: 13px;
}

.missing-field {
  border: 1px solid red;
  border-radius: 12px;
}

::v-deep .p-chips-multiple-container {
  flex-wrap: wrap;
  gap: 5px;
  border-radius: 10px;
}
</style>
